@import "../../App.scss";

.banner_img {
  width: 100vw;
  height: toRem(480);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: toRem(109) 0 toRem(126) toRem(310);
  min-height: 103px;
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/solution01.png);
}

.banner_img_text {
  font-size: toRem(20);
  font-family: AlibabaPuHuiTiR;
  color: #A7A7A7;
  margin-left: toRem(70);
  text-align: start;
}

.banner_img_title {
  width: toRem(650);
  background-repeat: no-repeat;
  justify-content: center;
  height: toRem(57);
  margin-top: toRem(30);
}

.banner_solution02_title {
  width: toRem(456);
  margin-top: toRem(30);
  justify-content: center;
}

.banner_img_mode {
  display: flex;
  font-size: toRem(17);
  font-family: AlibabaPuHuiTiR;
  color: #FFFFFF;
  margin-left: toRem(50);

  div {
    margin-left: toRem(10);
  }
}

.banner_rectangle_big {
  display: flex;
  align-items: center;
  width: toRem(672);
  height: toRem(70);
  color: #FFFFFF;
  font-size: toRem(16);
  justify-content: center;
  margin-left: toRem(-195);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/bck_rectangle_big.png);
  min-width: 270px;
}

.banner_solution03_image {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  width: toRem(722);
  height: toRem(225);
  min-width: 150px;
  min-height: 80px;
}

.banner_img_btn {
  width: toRem(215);
  height: toRem(48);
  border: 1px solid;
  border-radius: toRem(10);
  border-image: linear-gradient(122deg, rgba(255, 211, 43, 1), rgba(255, 175, 27, 1)) 1 1;
  margin-top: toRem(16);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div {
    width: toRem(74);
    min-width: 50px;
    font-size: toRem(18);
    font-family: AlibabaPuHuiTiR;
    color: #FFB119;
    margin-right: toRem(10);
  }

  img {
    width: toRem(21);
    height: toRem(20);
  }
}

.banner_science_line {
  width: toRem(230);
  height: toRem(20);
}

.banner_user {
  width: 100vw;
  height: toRem(480);
}

.banner_user_elem {
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  .banner_img {
    height: auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner_img_btn {
    height: auto;
  }
  .banner_img_btn {
    border: 0 solid;
  }
  .banner_img_btn {
    margin-left: 20px;
  }
}