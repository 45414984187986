@import "src/assets/font/font";

$rem: 192;
@function toRem($px) {
  @return ($px/$rem)*1rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
}

.container_pad {
  padding: toRem(120) toRem(250) toRem(120) toRem(250);
}

.banner-img {
  height: toRem(500);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-title {
  font-size: toRem(40);
  font-weight: 600;
  color: #FFFFFF;
  line-height: 56px;
  margin-left: toRem(317);
}

.banner-text {
  font-size: toRem(16);
  color: #999999;
  line-height: 24px;
  margin-left: toRem(317);
  margin-top: toRem(20);
  letter-spacing: 2px;

}

.title-small {
  font-size: toRem(40);
  font-family: AlibabaPuHuiTiB;
  font-weight: 600;
  color: #333333;
}

.title-tiny {
  font-size: toRem(20);
  font-weight: 600;
  color: #333333;
}

.text-small {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  color: #666666;
}


.example-card-title-text {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.example-card-title-dived {
  width: 40px;
  height: 2px;
  background: #FFB119;
  border-radius: 1px;
  margin-left: auto;
  margin-right: auto;
}

.example-card-title {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.flex {
  display: flex;
}

.image-auto {
  width: 100%;
  height: auto;
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.title-color {
  color: #FFB119;
}

.text-space-small {
  letter-spacing: toRem(1.5);
}

.text-ali-fontR {
  font-family: AlibabaPuHuiTiR;
}

.text-ali-fontM {
  font-family: AlibabaPuHuiTiM;
}

.text-ali-fontB {
  font-family: AlibabaPuHuiTiB;
}

.text-ali-fontH {
  font-family: AlibabaPuHuiTiH;
}

.text-space-normal {
  letter-spacing: toRem(2);
}

.text-space-large {
  letter-spacing: toRem(3);
}


.text-line-normal {
  line-height: 30px
}

.text-line-small {
  line-height: 20px;
}

@media screen and (max-width: 1024px) {
  .title-tiny {
    font-size: 11px;
  }
  .text-small {
    font-size: 9px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

