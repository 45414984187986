@import "../../App.scss";

.device_pad {
  padding: toRem(94) toRem(570) toRem(120) toRem(570);
  background-color: #ffffff;

  img {
    width: toRem(165);
    height: toRem(180);
    min-width: 80px;
    min-height: 90px;
  }
}

.device_title {
  font-size: toRem(18);
  text-align: center;
  margin-top: toRem(20);
  font-family: AlibabaPuHuiTiM;
}

@media screen and (max-width: 576px) {
  .device_pad {
    padding-left: 0;
    padding-right: 0;
  }
  .device_title{
    font-size: 14px;
  }
}