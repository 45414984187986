@import "../../App.scss";

.pain_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pain_ped {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  height: toRem(750);
  width: 100%;
  min-height: 550px;
  padding: toRem(100) toRem(250) toRem(100) toRem(250);
}

.title_bck {
  width: toRem(1920);
  height: toRem(300);
  display: flex;
  justify-content: center;
  align-items: center;
}

.big_title {
  font-size: toRem(60);
  font-family: AlibabaPuHuiTiB;
  color: #333C4F;
  letter-spacing: 3px;
  background: linear-gradient(309deg, #62CAFA 0%, #70E6ED 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  width: 100vw;
}

.big_title_orange {
  font-size: toRem(60);
  font-family: AlibabaPuHuiTiB;
  color: #FFB119;
  letter-spacing: 3px;
  background: linear-gradient(122deg, #FFD32B 0%, #FFAF1B 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  width: 100vw;
}

.optional_pad {
  padding: toRem(80) toRem(310) toRem(80) toRem(310);
}

.stall_adv {
  height: toRem(800);
  min-height: 600px;
}

.solution_value {
  height: toRem(780);
  padding: toRem(80) toRem(310) toRem(80) toRem(310);
  min-height: 630px;
}

.classic_case {
  height: toRem(1040);
  min-height: 800px;
  padding: toRem(80) toRem(310) toRem(80) toRem(310);
}

.mode_adv_img {
  width: toRem(96);
  height: toRem(96);
  min-width: 60px;
  min-height: 60px;
}

.adv_container {
  width: toRem(1620);
  height: toRem(350);
  padding: toRem(50) 0 toRem(50) 0;
  margin-top: toRem(40);
  background: linear-gradient(180deg, #FAFAFA 0%, #FFFFFF 100%);
  box-shadow: 0px 2px 20px 0px rgba(62, 89, 123, 0.1);
}

.meal_process {
  width: 100%;
  height: toRem(555);
}

.step_num_img {
  width: toRem(72);
  height: toRem(58);
  margin-left: toRem(60);
}

.step_text {
  width: toRem(196);
  font-size: toRem(16);
  color: #666666;
  margin-top: toRem(30);
  font-family: AlibabaPuHuiTiR;
  margin-left: toRem(5);
  //text-align: center;
}

.step_img {
  width: toRem(196);
  height: toRem(166);
}


.gate_step_bck {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: toRem(640);
  height: toRem(230);
  padding-top: toRem(20);
  padding-left: toRem(55);
  padding-bottom: toRem(20);
  min-width: 250px;
  min-height: 80px;
  font-family: AlibabaPuHuiTiM;
}

.gate_step_img {
  width: toRem(250);
  height: toRem(140);
}


.line {
  width: toRem(68);
  height: toRem(2);
  margin-top: toRem(55);
}

.mode_adv_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #FAFAFA 0%, #FFFFFF 100%);
  box-shadow: 0px 2px 20px 0px rgba(62, 89, 123, 0.1);
  padding: toRem(40) toRem(140) toRem(40) toRem(140);
}

.program_value_card {
  width: 179px;
  height: 178px;
  background: linear-gradient(180deg, #FAFAFA 0%, #FFFFFF 100%);
  box-shadow: 0px 2px 20px 0px rgba(62, 89, 123, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: AlibabaPuHuiTiM;
}

.example_classic_card {
  width: toRem(409);
  height: toRem(550);
  background: #FFFFFF;
  box-shadow: 0px 6px 26px 5px rgba(0, 59, 140, 0.07);
  border-radius: 15px;
  min-width: 270px;
  margin-left: auto;
  margin-right: auto;
  min-height: 480px;
}

@media only screen and (max-width: 1200px) {
  .adv_container {
    height: auto;
  }
  .stall_adv {
    height: auto;
  }
  .solution_value {
    height: auto;
  }
  .classic_case {
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .pain_ped {
    height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .gate_step_img {
    width: 90px;
    height: 60px;
  }
  .stall_adv {
    height: auto;
  }
  .meal_process {
    height: auto;
  }
}