@import "../../App.scss";

.device_pad {
  padding: toRem(120) toRem(120) toRem(120) toRem(120);
  background-color: #ffffff;
}

.device_title {
  font-size: toRem(18);
  font-weight: bold;
  text-align: center;
  margin-top: toRem(20);
}

.function_img {
  width: toRem(330);
  height: toRem(440);
  min-width: 270px;
  min-height: 300px;
}

.banner_head_title {
  font-size: toRem(30);
  color: #B55D2D;
  font-family: AlibabaPuHuiTiM;
}

.function_pad {
  height: toRem(2448);
  min-height: 2050px;
  background-color: red;
  padding: toRem(95) toRem(305) toRem(205) toRem(310);
}

.page_show_container {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
}

@Media only screen and (max-width: 1200px) {
  .function_pad {
    height: auto;
    min-height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .banner_head_title {
    font-size: 11px;
  }
}