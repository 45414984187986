@import "../../App.scss";

.banner_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: toRem(93);
  padding-right: 0;
  padding-bottom: toRem(156);

}

.banner_title {
  font-size: toRem(30);
  font-weight: 600;
  line-height: 42px;
}

.banner_text {
  width: toRem(570);
  font-size: toRem(16);
  font-weight: 400;
  line-height: 28px;
  min-width: 250px;
  margin-top: toRem(17);
}

@media screen and (max-width: 1024px) {
  .banner_text {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .banner_container {
    padding-left: 10px;
    padding-right: 10px;
    background-size: cover;
  }
  .banner_title{
    text-align: center;
    font-size: 14px;
  }
  .banner_text{
    font-size: 12px;
  }
}