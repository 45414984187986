@import "../../App.scss";

.foot_bck {
  width: 100%;
  background: #333333;
  overflow: hidden;
  padding: toRem(141) toRem(310) toRem(56) toRem(300);
}

.foot_left {
  display: flex;
  flex-direction: column;
}

.foot_left_title {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #CFCFCF;
}

.foot_left_big_text {
  font-size: toRem(26);
  font-family: AlibabaPuHuiTiB;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 33px;
}

.foot_left_text {
  font-size: toRem(16);
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #FFFFFF;
}

.foot_icon {
  width: 35px;
  height: 35px;
  padding: toRem(5);
  border-radius: 50%;
  border: solid rgb(102, 102, 102) toRem(2);
}

.foot_right {
  display: flex;
  flex-direction: column;
}

.foot_right_title {
  font-size: toRem(20);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #CFCFCF;
  cursor: pointer;
}

.foot_left_wechat {
  font-size: toRem(14);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #B5B5B5;
  line-height: 20px;
  text-align: center;
}

.foot_right_text {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #B5B5B5;
  margin-top: 16px;
  cursor: pointer;
}

.foot_bottom_text {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #B5B5B5;
  margin-top: toRem(73);
}

.foot_dived {
  width: 2px;
  height: 383px;
  border: 1px solid rgba(151, 151, 151, 0.38);
}

@media screen and (max-width: 1024px) {
  .foot_bck {
    padding-right: 0;
  }
  .foot_left_title {
    font-size: 12px;
  }
  .foot_left_big_text {
    font-size: 11px;
  }
  .foot_left_text{
    font-size: 11px;
  }
  .foot_right_title{
    font-size: 13px;
  }
  .foot_right_text{
    font-size: 11px;
  }
  .foot_bottom_text{
    font-size: 11px;
  }
}
