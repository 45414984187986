@import "../../App.scss";


.device_title_container {
  display: flex;
  flex-direction: column;
  width: toRem(1920);
  padding-top: toRem(76);
  padding-bottom: toRem(86)
}

.device_title_container_title {
  font-size: toRem(50);
  font-family: AlibabaPuHuiTiM;
  letter-spacing: 8px;
  text-align: center;
}

.face_device_title_container_text {
  font-size: toRem(80);
  font-family: AlibabaPuHuiTiB;
  color: #333C4F;
  letter-spacing: 4px;
  background: linear-gradient(309deg, #62CAFA 0%, #70E6ED 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.device_container {
  width: toRem(1920);
  border-radius: 10px;
  padding-top: toRem(106);
  display: flex;
  justify-content: center;
}

.face_device_container_pop {
  width: 236px;
  height: 89px;
  background: #FFB119;
  box-shadow: 0px 2px 20px 0px rgba(7, 89, 127, 0.23);
}

.device_popover {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: toRem(24);
  color: #FFFFFF;
  text-align: center;
  font-family: AlibabaPuHuiTiM;
}

.face_pop_yellow {
  width: toRem(276);
  height: toRem(126);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/yellow_pop.png);
  padding-bottom: toRem(25);
  min-width: 120px;
  min-height: 100px
}

.face_pop_green {
  width: toRem(260);
  height: toRem(120);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/green_pop.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: toRem(24);
  color: #FFFFFF;
  padding-bottom: toRem(25);
  min-width: 120px;
  min-height: 100px;
  position: relative;
  bottom: toRem(30);
}

.face_pop_orange_red {
  width: toRem(326);
  height: toRem(126);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/orange_red_pop.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: toRem(24);
  color: #FFFFFF;
  padding-bottom: toRem(25);
  min-width: 120px;
  min-height: 100px
}

.device_text_container {
  width: toRem(555);
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid;
  border-image: linear-gradient(135deg, rgba(180, 246, 255, 0.43), rgba(231, 255, 253, 1), rgba(97, 144, 255, 0.27)) 1 1;
  padding: toRem(36) toRem(80) toRem(40) toRem(80);
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  color: #333C4F;
  min-width: 260px;
}

.device_container_device {
  width: toRem(348);
  height: toRem(652);
}

.process_pad {
  padding: toRem(60) toRem(310) toRem(80) toRem(310);
  background-color: #ffffff;
}

.bind_plate_device_bck {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/backgroundImage/bck_technical_specifications.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: toRem(130) toRem(310) toRem(130) toRem(310);
  width: toRem(1920);
}

.card_device_title_container_text {
  font-size: toRem(80);
  font-family: AlibabaPuHuiTiB;
  color: #333C4F;
  letter-spacing: 4px;
  background: linear-gradient(122deg, #FFD32B 0%, #FFAF1B 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.green_card {
  width: toRem(150);
  height: toRem(150);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/green_round.png);
  padding-bottom: toRem(10);
  min-width: 80px;
  min-height: 60px;
  position: relative;
  bottom: toRem(140);
}

.orange_red_card {
  width: toRem(180);
  height: toRem(180);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/orange_red_round.png);
  padding-bottom: toRem(10);
  min-width: 80px;
  min-height: 60px;
  position: relative;
  bottom: toRem(90);
}

.yellow_card {
  width: toRem(180);
  height: toRem(180);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/yellow_round.png);
  padding-bottom: toRem(10);
  min-width: 80px;
  min-height: 60px;
  position: relative;
  bottom: toRem(15);
}

.auto_device_title_container_text {
  font-size: toRem(80);
  font-family: AlibabaPuHuiTiB;
  color: #FFB119;
  letter-spacing: 4px;
  background: linear-gradient(122deg, #95A3F1 0%, #B097FE 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.green_auto {
  width: toRem(164);
  height: toRem(168);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/green_popover_round.png);
  padding-bottom: toRem(10);
  min-width: 80px;
  min-height: 60px;
  position: relative;
  top: toRem(40);
}

.orange_red_auto {
  width: toRem(164);
  height: toRem(168);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/orange_red_popover_round.png);
  padding-bottom: toRem(10);
  min-width: 80px;
  min-height: 60px;
  position: relative;
  bottom: toRem(5);
}

.yellow_auto {
  width: toRem(146);
  height: toRem(148);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/yellow_popver_round.png);
  padding-bottom: toRem(10);
  min-width: 80px;
  min-height: 60px;
  position: relative;
  bottom: toRem(50);
}

.operation_video {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: toRem(1920);
  height: toRem(600);
  min-width: 270px;
  min-height: 220px;
  //object-fit: fill;
}

@media screen and (max-width: 1024px) {
  .device_popover {
    font-size: 11px;
  }
  .device_text_container{
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .process_pad {
    padding-left: 10px;
  }
}