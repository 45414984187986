@import "../../App.scss";

.container {
  width: toRem(341);
  height: toRem(620);
  background: #FFFFFF;
  box-shadow: 0 6px 26px 2px rgba(0, 59, 140, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: toRem(44) toRem(40) 0 toRem(40);
  min-height: 536px;
  min-width: 270px;
}

.mode_img {
  width: 100%;
  height: auto;
}
.mode_img_container{
  width: 100%;
  height: toRem(200);
  min-width: 221px;
  min-height: 185px;
}

.title {
  font-size: 24px;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin-top: toRem(30);
}

.text {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #666666;
  margin-top: toRem(30);
  line-height: toRem(32);
  letter-spacing: toRem(2);
  text-align: justify;
}

@media screen and (max-width: 1024px) {
  .text {
    line-height: 30px;
    font-size: 20px;
  }
}
