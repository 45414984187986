@import "../../App.scss";

.device_bck {
  padding: toRem(93) 0 toRem(92) toRem(310);
  background-repeat: no-repeat;
  background-size: cover;
  width: toRem(1920);
  height: toRem(366);
}

.device_bck_title {
  font-size: toRem(30);
  font-weight: 600;
  color: #333333;
}

.device_bck_text {
  width: toRem(570);
  font-size: toRem(16);
  font-weight: 400;
  color: #666666;
  line-height: toRem(28);
  margin-top: toRem(17);
  min-width: 130px;
  letter-spacing: 2px;
}

.device_bck_more {
  display: flex;
  align-items: center;
  margin-top: toRem(16);
}

.device_bck_more_divider {
  width: toRem(138);
  height: toRem(1);
  border: toRem(1) solid #FFB119;
  min-height: 1px;
  margin-top: toRem(10);
  min-width: 80px;
}

.device_pad {
  padding: toRem(120) toRem(120) toRem(120) toRem(120);
  background-color: #ffffff;
}

.device_title {
  font-size: toRem(18);
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: toRem(20);
}

.device_bck_more_title {
  font-size: toRem(16);
  font-family: AlibabaPuHuiTiR;
  color: #FFB119;

}

.adv_container {
  width: 100%;
  height: auto;
}

.adv_icon_container {
  width: toRem(568);
  height: toRem(539);
  background-repeat: no-repeat;
  background-size: cover;
}

.adv_icon_container_title {
  font-size: toRem(20);
  font-family: AlibabaPuHuiTiM;
  color: #FFB119;
  margin-top: toRem(100);
}

.adv_icon_container_text {
  font-size: toRem(16);
  color: #666666;
  margin-top: toRem(15);
}

.contrast_intel {
  width: toRem(719);
  height: toRem(780);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/backgroundImage/intelligence_canteen.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: toRem(76) toRem(94) toRem(60) toRem(91);
  min-width: 270px;
}

.contrast_title {
  font-size: toRem(24);
  text-align: center;
  margin-bottom: toRem(31);
  font-family: AlibabaPuHuiTiB;
}

.contrast_text {
  font-size: toRem(16);
  font-weight: 500;
  margin-top: toRem(15);
  text-align: center;
}

.contrast_divider {
  width: 100%;
  height: toRem(1);
  border: toRem(1) solid #FFC34F;
  min-height: 1px;
  margin-top: toRem(16);
}

.contrast_vs {
  width: toRem(161);
  height: toRem(162);
  position: relative;
  top: 35%;
  right: 90%;
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/backgroundImage/icon_round.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-size: toRem(50);
  font-weight: normal;
  color: #FFB119;
  letter-spacing: 1px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 300;
  min-width: 150px;
  min-height: 151px;
  font-family: AlibabaPuHuiTiB;
}

.contrast_traditional {
  width: toRem(598);
  height: toRem(722);
  background: #FCFCFD;
  box-shadow: 0 toRem(2) toRem(10) 0 rgba(0, 0, 0, 0.05);
  padding: toRem(47) toRem(38) toRem(51) toRem(47);
  min-width: 270px;
  margin-top: toRem(30);
  margin-left: toRem(-170);
}

.contrast_traditional_img {
  width: 100%;
  height: toRem(234);
  margin-top: toRem(23);
}


@media screen and (max-width: 768px) {
  .device_bck_title{
    font-size: 14px;
  }
  .device_bck_text{
    font-size: 12px;
  }
  .device_bck {
    height: auto;
  }
  .device_bck_text {
    line-height: normal;
    width: auto;
  }
  .contrast_vs {
    position: static;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
  }
  .contrast_traditional_img {
    height: auto;
  }
  .contrast_title{
    font-size: 14px;
  }
  .contrast_text{
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .contrast_traditional {
    height: auto;
    margin-left: 0;
  }
  .contrast_intel {
    height: auto;
  }
}