@import "../../App.scss";

.nav {
  display: flex;
  height: toRem(82);
  width: 100%;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
}

.nav_logo {
  width: toRem(129);
  height: toRem(43);
  margin-left: toRem(300);
}

.nav_item_list {
  margin-right: toRem(300);
  width: toRem(917);
  display: flex;
  justify-content: space-between;
}

.nav_item {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: toRem(140);
  cursor: pointer;
}

.nav_item_text {
  display: flex;
  align-items: center;
  height: toRem(42);
  font-size: toRem(17);
  font-family: PingFangSC-Semibold, PingFang SC;
}

.nav_hover_selected {
  width: toRem(52);
  height: toRem(3);
  background: linear-gradient(122deg, #FFD32B 0%, #FFAF1B 100%);
  border-radius: toRem(2);
}

.nav_hover_text_selected {
  font-weight: 600;
  color: #333333;
}

.nav_hover_text_no_selected {
  font-weight: 400;
  color: #666666;
}

.product_menu {
  display: flex;
  justify-content: space-between;
}

.product_menu_title {
  width: toRem(14);
  height: toRem(5);
  font-size: toRem(36);
  font-family: AlibabaPuHuiTiM;
  color: #333333;
}


.nav_product_items {
  height: toRem(350);
  min-height: 300px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: toRem(61) toRem(200) toRem(60) toRem(200);
}

.nav_product_line {
  width: toRem(2);
  height: 90%;
  background-color: #D9D9D9;
  margin-top: toRem(20);
}

.nav_product_device {
  width: toRem(500);
}

.nav_product_software {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.nav_product_device_title {
  height: toRem(25);
  width: toRem(182);
  font-size: toRem(18);
  color: #333333;
  cursor: pointer;
  font-weight: 500;
  font-family: AlibabaPuHuiTiM;
}

.nav_product_device_list {
  display: flex;
  flex-direction: column;

  div {
    margin-top: 0.2rem;
  }
}

.nav_product_device_list_span {
  margin-top: toRem(15);
  font-size: toRem(16);
  font-family: AlibabaPuHuiTiR;
}


.mask {
  position: fixed;
  height: 100%;
  top: 8%;
  width: 100%;
  z-index: 100;
  background: #000000;
  opacity: 0.6;
}
@media screen and (max-width: 1200px) {
  .nav_product_software{
    width: 100%;
  }
}