@import "../../App.scss";

.nav {
  width: toRem(1920);
  background: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  height: toRem(60);
  min-height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: toRem(210);
  padding-right: toRem(210)
}

.banner_user {
  width: toRem(1000);
  height: toRem(800);
}

.case_card_image {
  background-size: cover;
  background-repeat: no-repeat;
  width: toRem(1100);
  height: auto;
}

.banner_user_elem {
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.nav_title {
  font-size: toRem(18);
  color: #666666;
  cursor: pointer;
  font-family: AlibabaPuHuiTiR;
  //line-height: 25px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: toRem(60) toRem(310) toRem(20) toRem(310);
}

.page_arrow {
  font-size: toRem(14);
  font-family: Helvetica;
  color: #000000;
  line-height: 17px;
}

.example_classic_card {
  width: toRem(409);
  height: toRem(550);
  background: #FFFFFF;
  box-shadow: 0px 6px 26px 5px rgba(0, 59, 140, 0.07);
  border-radius: 15px;
  min-width: 270px;
  margin-left: auto;
  margin-right: auto;
  min-height: 480px;
}

.modal_arrow {
  width: toRem(100);
  height: toRem(150);
}

@media screen and (max-width: 1024px) {
  .page_arrow {
    font-size: 11px;
  }
  .nav_title{
    font-size: 11px;
  }
}