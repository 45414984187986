@import "../../App.scss";

.accessory_card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EEF1F6;
  height: toRem(862);
}

.accessory_card_container_img {
  background-size: cover;
  background-repeat: no-repeat;
  width: toRem(968);
  height: toRem(862);
}

.operation_video {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: toRem(59);
  height: toRem(600);
  min-height: 220px;
}

.accessory_card_container_title {
  font-size: toRem(50);
  color: #333333;
  font-family: AlibabaPuHuiTiB;
}

.accessory_card_container_text {
  width: toRem(420);
  font-size: toRem(16);
  color: #999999;
  margin-top: toRem(36);
  font-family: AlibabaPuHuiTiR;
}

.pad {
  padding: toRem(120) toRem(310) toRem(120) toRem(310);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 270px;
}

.operation_container {
  background-color: #ffffff;
  padding: toRem(60) toRem(86) toRem(80) toRem(80);
}

.tech_container {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: toRem(1920);
  padding: toRem(125) 0 toRem(125) toRem(310);
}

.accessory_card_container_bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.accessory_card_container_bottom_text {
  width: toRem(200);
  font-size: toRem(16);
  color: #666666;
  margin-top: toRem(22);
  text-align: center;
  font-family: AlibabaPuHuiTiR;
}

.accessory_card_container_btn {
  width: toRem(362);
  height: toRem(53);
  border-radius: toRem(27);
  border: 1px solid #FFB119;
  margin-left: auto;
  margin-right: auto;
  margin-top: toRem(110);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  min-height: 30px;
}

.accessory_card_container_btn_not_active {
  font-size: toRem(18);
  width: 50%;
  height: toRem(53);
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 30px;
}

.accessory_card_container_btn_active {
  width: 50%;
  height: toRem(53);
  background: #FFB119;
  border-radius: toRem(27);
  font-size: toRem(18);
  color: #333333;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.tech_bracelet {
  width: toRem(696);
  min-width: 250px;
  background: #FFFFFF;
  border-radius: toRem(19);
  opacity: 0.75;
  padding: toRem(78) toRem(60) toRem(78) toRem(90);
}

.tech_bracelet_title {
  font-size: toRem(40);
  color: black;
  text-align: center;
  letter-spacing: 1px;
  font-family: AlibabaPuHuiTiB;
}

.tech_bracelet_card_title {
  font-size: toRem(18);
  color: #333333;
  font-family: AlibabaPuHuiTiM;
}

.tech_bracelet_card_text {
  font-size: toRem(18);
  color: #999999;
  margin-top: toRem(8);
}

.tech_bracelet_card_margin {
  margin-left: toRem(20);
}

@media screen and (max-width: 576px) {
  .accessory_card_container_text{
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .accessory_card_container {
    height: auto;
  }
  .accessory_card_container_btn {
    width: 100%;
  }
}

@media screen and (max-width: 1024px){
  .accessory_card_container_title{
    font-size: 25px;
  }
  .accessory_card_container_text{
    font-size: 12px;
  }
  .accessory_card_container_bottom_text{
    font-size: 11px;
  }
  .accessory_card_container_btn_active{
    font-size: 11px;
  }
  .accessory_card_container_btn_not_active{
    font-size: 11px;
  }
  .tech_bracelet_card_title{
    font-size: 11px;
  }
  .tech_bracelet_card_text {
    font-size: 11px;
  }
}