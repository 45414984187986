@import "../../App.scss";

.news_card_container {
  display: flex;
  height: toRem(900);
  flex-direction: column;
  padding: toRem(80) toRem(310) toRem(80) toRem(310);
  min-height: 710px;
}

.new_list_container {
  display: flex;
  flex-direction: column;
  padding: toRem(80) toRem(310) toRem(80) toRem(310);
}

.news_big_title {
  font-size: toRem(32);
  font-family: AlibabaPuHuiTiM;
  color: #333333;
  letter-spacing: 1px;
  margin-left: toRem(30);
}


.example_classic_card {
  height: toRem(600);
}

.example_classic_card_img {
  width: 100%;
  height: toRem(350);
  background-size: cover;
  min-height: 210px;
}

.new_vertical_line {
  width: 1px;
  height: toRem(92);
  opacity: 0.62;
  border: 1px solid #D9D9D9;
}

.new_list_item {
  display: flex;
  align-items: center;
  margin-top: toRem(50);
}

.news_day {
  font-size: toRem(54);
  font-family: AlibabaPuHuiTiM;
  color: #333333;
  line-height: 74px;
  letter-spacing: 2px;
}

.new_year_month_text {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  color: #333333;
  line-height: 25px;
}

.news_item_title {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiM;
  color: #333333;
  line-height: 25px;
  cursor: pointer;
}

.news_item_title:hover {
  color: #FFAF1B;
}

.news_item_abstract {
  font-size: toRem(17);
  color: #999999;
  line-height: 28px;
  font-family: AlibabaPuHuiTiR;
}

.news_date {
  display: flex;
  align-items: center;
  margin-right: toRem(30);
}

.new_line {
  width: toRem(1300);
  height: 1px;
  border: 1px solid #D9D9D9;
  margin-top: toRem(40);
}

.news_pagination {
  display: flex;
  justify-content: center;
  margin-top: toRem(40);
  align-items: center;
}

.page_arrow {
  font-size: toRem(14);
  color: #000000;
  line-height: 17px;
}

@media screen and (max-width: 1400px) {
  .example_classic_card {
    min-height: 550px
  }
}

@media screen and (max-width: 900px) {
  .news_card_container{
    min-height: 800px;
  }
  .example_classic_card {
    min-height: 630px
  }
  .news_big_title{
    font-size: 13px;
  }
  .new_year_month_text{
    font-size: 13px;
  }
  .news_item_title{
    font-size: 12px;
  }
  .news_item_abstract{
    font-size: 11px;
  }
  .page_arrow{
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .news_card_container {
    height: auto;
  }
}
