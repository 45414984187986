@import "../../App.scss";

.technology_container {
  align-items: center;
}

.technology_title {
  font-size: toRem(40);
  font-weight: bold;
  letter-spacing: 1px;
}

.technology_img {
  width: toRem(45);
  height: toRem(50);
  min-width: 30px;
  min-height: 35px;
}

.title {
  font-size: toRem(18);
  font-weight: 600;
  font-family: AlibabaPuHuiTiM;
}

.text {
  font-size: toRem(18);
  margin-top: toRem(8);
  font-family: AlibabaPuHuiTiR;
}

.text_container {
  margin-left: toRem(20);
}

.technology_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: toRem(60);
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 11px;
  }
  .text {
    font-size: 11px;
  }
}

@media screen and (max-width: 576px) {
  .technology_container {
    overflow-x: scroll;
  }
  .technology_img {
    width: 50px;
    height: auto;
  }
}