@import "../../App.scss";


.title_container {
  display: flex;
  align-items: center;
}

.title {
  font-size: toRem(30);
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: toRem(10);
  font-family: AlibabaPuHuiTiM;
}

.step {
  width: 100%;
  display: flex;
  margin-top: toRem(20);
}

.round {
  width: toRem(25);
  height: toRem(25);
  background: #FFB119;
  border-radius: 50%;
  text-align: center;
  min-width: 25px;
  min-height: 25px;
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiM;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_text {
  width: toRem(746);
  font-size: toRem(18);
  white-space: nowrap;
  color: #666666;
  margin-left: toRem(25);
  font-family: AlibabaPuHuiTiR;
}

.vertical_line {
  width: 3px;
  height: toRem(24);
  background: #FFB119;
}

.arrow {
  margin-left: toRem(38);
  height: toRem(12);
  min-width: 60px;
}

@media screen and (max-width: 1024px) {
  .round{
    font-size: 10px;
  }
  .step_text{
    font-size: 10px;
  }
}