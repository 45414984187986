@import "../../App.scss";

.container {
  width: toRem(1305);
  background: #FFFFFF;
  border-radius: toRem(15);
  padding: 0 toRem(100) 0 toRem(100);
  min-height: 458px;
  min-width: 270px;
}

.round {
  width: toRem(9);
  height: toRem(9);
  background: #FFB119;
  min-width: 9px;
  min-height: 9px;
  border-radius: 50%;
  margin-top: toRem(7);
}

.title {
  font-size: toRem(32);
  color: #FFAF1B;
  font-weight: 500;
  margin-left: toRem(20);
  font-family: AlibabaPuHuiTiB;
}

.text {
  font-size: toRem(16);
  color: #7F635A;
  line-height: toRem(26);
  margin-left: toRem(11);
  font-family: AlibabaPuHuiTiR;
}

@media screen and (max-width: 1000px) {
  .container {
    height: auto;
  }
  .text{
    line-height: normal;
  }
  .title {
    font-size: 18px;
    text-align: center;
  }
  .text{
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    width: 100%;
  }
  .text {
    width: 100%;
  }
}
