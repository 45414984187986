.cubic {
  position: fixed;
  text-align: center;
  z-index: 999;
  left: 0;
  right: 0;
  top: 40%;
}

.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  opacity: 0.7;
  z-index: 888;
}

.onMove {
  animation: on_roate 3s infinite;
}

@keyframes on_roate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}