@import "../../App.scss";

.banner_img {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/about/banner/banner.png);
  width: toRem(1920);
  height: toRem(750);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  padding: toRem(200) 0 0 toRem(310);
}

.left_angle {
  width: toRem(26);
  height: toRem(26);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  float: left;
}

.banner_title {
  font-size: toRem(50);
  font-family: AlibabaPuHuiTiH;
  color: #5B370B;
}

.banner_about_title {
  font-size: toRem(28);
  font-family: AlibabaPuHuiTiR;
  color: #8E6800;
  letter-spacing: 10px;
  margin-top: toRem(15);
}

.banner_about_text {
  font-size: toRem(16);
  font-family: AlibabaPuHuiTiR;
  color: #8E6800;
  margin-top: toRem(15);
}

.text_container {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/yiwo.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: toRem(809);
  height: toRem(610);
  position: absolute;
  top: toRem(488);
  left: toRem(310);
  min-width: 270px;
  padding: toRem(86) toRem(173) toRem(100) toRem(60);
  font-size: toRem(17);
  color: #333333;
  line-height: toRem(32);
  display: flex;
  flex-direction: column;

  div {
    text-indent: toRem(30);
  }

  span {
    color: #FFB119;
    font-size: toRem(20);
  }
}

.summary_container {
  display: flex;
  width: toRem(1300);
  height: toRem(330);
  background: linear-gradient(61deg, #FFAF1B 0%, #FFD32B 100%);
  border-radius: 30px;
  min-height: 200px;
  margin: toRem(50) auto toRem(80);
  justify-content: flex-end;
  padding: toRem(65) toRem(65) toRem(65) 0;
  min-width: 270px;
}

.summary_container_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.summary_container_card_title {
  font-size: toRem(20);
  color: #5B370B;
  line-height: toRem(27);
  font-family: AlibabaPuHuiTiM;
}

.summary_container_card_text {
  font-family: AlibabaPuHuiTiR;
  font-size: toRem(16);
  color: #8E6800;
  line-height: toRem(22);
}

.summary_container_card_dived {
  width: 25px;
  height: 3px;
  background: rgba(255, 255, 255, 0.55);
}
.honor_container{
  height: toRem(1099);
  min-height: 950px;
}

.brand_adv {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/about/backgroundImage/brand_adv.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: toRem(1920);
  height: toRem(872);
}

.brand_adv_title {
  color: #333333;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: toRem(80);
}

.brand_adv_text {
  font-size: toRem(16);
  font-family: AlibabaPuHuiTiR;
  color: #666666;
  margin-top: toRem(20);
}

.concat_container {
  width: toRem(1300);
}

.concat {
  width: toRem(640);
  height: toRem(174);
  background: #FFFFFF;
  border-radius: 10px;
  padding: toRem(35) toRem(70) toRem(35) toRem(60);
  display: flex;
  min-width: 270px;
  align-items: center;
  min-height: 80px;
}

.concat_container_pad {
  padding: toRem(80) toRem(310) toRem(100) toRem(309);
}

.concat_title {
  font-size: toRem(26);
  font-family: AlibabaPuHuiTiM;
  color: #5B370B;
  letter-spacing: 1px;
}

.concat_dived {
  width: toRem(390);
  height: 1px;
  border: 1px solid #DBDFE7;
  margin-top: toRem(20);
}

.concat_text {
  font-size: toRem(24);
  font-family: AlibabaPuHuiTiR;
  color: #5B370B;
  letter-spacing: 1px;
  margin-top: toRem(20);
}

.concat_big {
  width: toRem(1300);
  background: #FFFFFF;
  border-radius: 10px;
  min-width: 270px;
  display: flex;
  padding: toRem(35) toRem(80) toRem(35) toRem(60);
  align-items: center;
  min-height: 100px;
}

.concat_dived_big {
  width: toRem(1010);
  height: 1px;
  border: 1px solid #DBDFE7;
  margin-top: toRem(20);
}

.concat_map {
  width: toRem(1300);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: toRem(80);
}

@media screen and (max-width: 1200px) {
  .summary_container_card_text {
    line-height: 18px;
  }
  .text_container {
    width: auto;
    height: auto;
    position: static;
    display: flex;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .honor_container{
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .summary_container_card_text {
    line-height: 18px;
    font-size: 12px;
  }
  .text_container {
    height: auto;
    left: 1vw;
    line-height: 20px;
    font-size: 14px;
    span{
      font-size: 14px;
    }
  }
  .summary_container_card_title{
    font-size: 14px;
  }
  .brand_adv {
    width: 100%;
  }
  .concat_title{
    font-size: 13px;
  }
  .concat_text{
    font-size: 11px;
  }
  .concat{
    width: 100%;
  }
}