@import "../../App.scss";

.device_pad {
  height: toRem(480);
  padding: toRem(120) toRem(120) toRem(120) toRem(120);
}

.device_title {
  font-size: toRem(18);
  text-align: center;
  margin-top: toRem(20);
  font-family: AlibabaPuHuiTiM;
}

@media screen and (max-width: 1024px){
  .device_title{
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .device_pad{
    height: auto;
  }
}