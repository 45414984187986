@import "../../App.scss";

.carousel_container {
  width: 100vw;
  background: linear-gradient(180deg, #0F0F0F 0%, #0F0F0F 100%);
}

.carousel_img {
  width: toRem(1006);
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 270px;
}

.carousel_arrow {
  width: toRem(108);
  height: toRem(159);
  min-width: 50px;
  min-height: 70px;
}

.func_character_title {
  font-size: toRem(20);
  color: #FFFFFF;
  margin-left: toRem(10);
  font-family: AlibabaPuHuiTiM;
}

.func_character_pad {
  width: 100%;
  padding: toRem(100) toRem(310) toRem(130) toRem(310);
}

.func_character{
  width: 100%;
  padding: toRem(100) toRem(310) toRem(130) toRem(310);
  height: toRem(1210);
  min-height: 800px;
}

.func_character_text {
  font-size: toRem(16);
  color: #B5B5B5;
  line-height: 22px;
  margin-top: toRem(15);
  margin-left: toRem(13);
  letter-spacing: 0.6px;
  font-family: AlibabaPuHuiTiR;
}

.func_character_round {
  width: toRem(5);
  height: toRem(5);
  border-radius: 50%;
  min-width: 5px;
  min-height: 5px;
  background: #C49D34;
  margin-top: toRem(16);
}

.func_character_dived {
  width: toRem(1300);
  height: toRem(1);
  border: 1px solid #333333;
  min-height: 1px;
  margin-top: toRem(57);
  min-width: 270px;
}

.func_character_img {
  width: toRem(267);
  height: toRem(150);
}

.pad {
  padding: toRem(120) toRem(310) toRem(120) toRem(310);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.operation_container {
  background-color: #222222;
  padding: toRem(30) toRem(125) toRem(30) toRem(70);
  min-width: 270px;
  width: toRem(1300);
  height: toRem(223);
  min-height: 240px;
}

.operation_container_arrow_short {
  margin-top: toRem(5);
  margin-left: 85%;
}

.round {
  width: toRem(25);
  height: toRem(25);
  background: #FFB119;
  border-radius: 50%;
  text-align: center;
  min-width: 25px;
  min-height: 25px;
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiM;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left_arrow {
  transform: rotate(180deg);
  margin-left: toRem(38);
  width: toRem(96);
  //min-width: 60px;
  height: toRem(15);
}

.operation_container_text {
  width: toRem(400);
  font-size: toRem(18);
  white-space: nowrap;
  color: #666666;
  margin-left: toRem(25);
}

.banner_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: toRem(48);
}

.banner_card_text {
  width: toRem(165);
  min-width: 150px;
  color: #B5B5B5;
  text-align: center;
}

.flat_func_item_dived {
  width: 90%;
  height: toRem(1);
  border: 1px solid #333333;
  min-width: 220px;
  min-height: 1px;
  margin-top: toRem(30);
}

.example_card {
  width: toRem(409);
  height: toRem(550);
  box-shadow: 0px 6px 26px 5px rgba(0, 59, 140, 0.07);;
  border-radius: 15px;
  min-width: 270px;
  margin-left: auto;
  margin-right: auto;
  min-height: 480px;
}

.operation_video {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: toRem(59);
  width: toRem(1420);
  height: toRem(600);
  min-width: 270px;
  min-height: 220px;
}

.operation_video_small {
  width: toRem(694);
  height: toRem(390);
  min-width: 270px;
  min-height: 220px;
}

@media screen and (max-width: 1250px) {
  .func_character_text {
    width: 250px;
  }
}

@media screen and (max-width: 1024px) {
  .func_character_pad {
    height: auto;
    min-height: auto;
  }
  .func_character_text {
    width: 200px;
  }
  .func_character{
    height: auto;
    min-height: auto;
  }
  .func_character_title{
    font-size: 14px;
  }
  .func_character_text{
    font-size: 11px;
  }
  .operation_container_text{
    font-size: 10px;
  }
  .round{
    font-size: 10px;
  }
}
@media screen and (max-width: 768px) {
  .func_character_text {
    width: 160px;
  }
}


@media screen and (max-width: 576px) {
  .operation_container_text {
    white-space: normal;
    width: 250px;
  }
  .func_character_pad {
    padding: toRem(100) 0 toRem(130) toRem(0);
  }
  .func_character{
    padding: toRem(100) 0 toRem(130) toRem(0);
  }

}
