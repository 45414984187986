@import "../../App.scss";

.example_classic_card {
  width: toRem(439);
  background: #FFFFFF;
  box-shadow: 0 6px 26px 5px rgba(0, 59, 140, 0.07);
  //border-radius: 15px;
  min-width: 270px;
  min-height: 600px;
}

.card_img {
  width: 100%;
  height: toRem(311);
  min-height: 230px;
  background-size: cover;
}

.card_title {
  font-family: AlibabaPuHuiTiM;
  font-size: toRem(18);
}

.card_text {
  font-family: AlibabaPuHuiTiR;
  line-height: toRem(30);
  letter-spacing: 1.5px;
  font-size: toRem(16);
  text-align: justify;
}

@media screen and (max-width: 1024px) {
  .card_text {
    line-height: normal;
    font-size: 14px;
  }
  .card_title{
    font-size: 16px;
  }
}