@import "../../App.scss";

.main_container {
  width: toRem(1920);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.solution_mode {
  width: 100%;
  height: toRem(850);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: toRem(100) toRem(210) toRem(140) toRem(210);
  overflow: hidden;
  justify-content: center;
}

.solution_mode_cards {
  margin-left: toRem(100);
  height: toRem(700);
}

.mode {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: toRem(100) toRem(210) toRem(140) toRem(210);
  overflow: hidden;
}

.mode_header {
  width: 100%;
  height: toRem(1100);
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: toRem(100) toRem(210) toRem(140) toRem(210);
  overflow: hidden;
}

.mode_news {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: toRem(100) toRem(210) toRem(140) toRem(210);
  overflow: hidden;
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/main/backgroundImage/bck_news.png);
  background-size: 100% 100%;
}

.mode_cases {
  width: 100%;
  display: flex;
  height: toRem(1080);
  min-height: 900px;
  flex-direction: column;
  align-items: center;
  padding: toRem(100) toRem(310) toRem(80) toRem(310);
  overflow: hidden;
}

.case_btn {
  width: toRem(197);
  height: toRem(57);
  min-width: 100px;
  min-height: 50px;
  color: #FFB119;
  border-color: #FFB119;
  font-size: toRem(18);
}

.mode_img {
  width: 100%;
  height: toRem(1080);
}


.mode_title {
  width: toRem(1920);
  font-size: toRem(40);
  font-family: AlibabaPuHuiTiB;
  color: #333333;
  text-align: center;
}

.mode_text {
  width: toRem(1920);
  font-size: toRem(20);
  color: #999999;
  text-align: center;
  letter-spacing: toRem(3);
  font-family: AlibabaPuHuiTiR;
}

.solution_row {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: toRem(1920);
  height: toRem(800);
  margin-top: toRem(100);
}

.solution_card {
  width: toRem(160);
  height: toRem(160);
  background: linear-gradient(180deg, #F5F5F5 3%, #FFFFFF 100%);
  box-shadow: 0px 2px 20px 0px rgba(62, 89, 123, 0.1);
  border-radius: 6px;
  border: 1px solid;
  border-image: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(242, 244, 255, 1)) 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: AlibabaPuHuiTiR;
  color: #666666;
  font-size: toRem(16);
  padding: toRem(16) 0 toRem(19) 0;
  min-width: 120px;
  min-height: 120px;
}

.solution_img {
  width: toRem(80);
  height: toRem(80);
  min-width: 40px;
  min-height: 40px;
}

.solution_title_color_blue {
  font-size: toRem(30);
  align-items: center;
  font-family: AlibabaPuHuiTiB;
  color: #333C4F;
  letter-spacing: 1px;
  background: linear-gradient(309deg, #62CAFA 0%, #70E6ED 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: toRem(80);
  text-align: center;
}

.solution_title_color_orange {
  white-space: nowrap;
  font-size: toRem(30);
  align-items: center;
  font-family: AlibabaPuHuiTiB;
  color: #333C4F;
  letter-spacing: 1px;
  background: linear-gradient(134deg, #FFCA00 0%, #FFAF1B 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: toRem(80);
  text-align: center;
}

.solution_dived {
  width: toRem(1433);
  height: toRem(2);
  margin-top: toRem(59);
  margin-bottom: toRem(59);
  border: 1px solid #D9D9D9;
  margin-left: toRem(180);
}

.adv_background {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/main/backgroundImage/background_adv.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: toRem(1080);
}

.adv_num {
  font-size: toRem(54);
  font-weight: bold;
  color: #834400;
  text-align: center;
}

.adv_sum {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: toRem(350);
}

.adv_text {
  width: 100%;
  height: 33px;
  font-size: toRem(24);
  color: #8C6620;
  text-align: center;
  font-family: AlibabaPuHuiTiR;
}

.adv_foot_text {
  font-size: toRem(20);
  color: #8C6620;
  display: flex;
  justify-content: center;
  font-family: AlibabaPuHuiTiR;
}

.adv_data_list {
  height: 1.1rem;
  width: 1.5rem;
  text-align: center;
}

.example_classic_card {
  width: toRem(409);
  height: 580px;
  background: #FFFFFF;
  box-shadow: 0px 6px 26px 5px rgba(0, 59, 140, 0.07);
  border-radius: 15px;
  min-width: 270px;
  margin-left: auto;
  margin-right: auto;
}

.monthly_rep_background {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/news/card/image03.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: toRem(692);
  height: toRem(383);
}

.monthly_yiwo_head {
  width: toRem(43);
  height: toRem(77);
}

.monthly_mess_title {
  font-size: toRem(24);
  color: #333333;
  font-family: AlibabaPuHuiTiB;
}

.monthly_mess_text {
  font-size: toRem(18);
  line-height: toRem(26);
  letter-spacing: 1.5px;
  color: #999999;
  cursor: pointer;
  font-family: AlibabaPuHuiTiR;
}

.monthly_mess_dot {
  width: toRem(24);
  height: toRem(18);
  border: 1px solid #FFB119;
  border-radius: 50%;
  margin-right: toRem(17);
  min-width: 12px;
  min-height: 12px;
}

.monthly_mess_text {
  width: 458px;
  height: 75px;
  font-size: toRem(16);
  font-family: AlibabaPuHuiTiR;
  color: #999999;
}

.monthly_background_rep_text {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  color: #FFFFFF;
  line-height: 25px;
  letter-spacing: 2px;
}

.monthly_background_rep_title {
  font-size: toRem(60);
  font-family: AlibabaPuHuiTiB;
  color: #FFFFFF;
  line-height: 82px;
}

.example_card_title {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.example_card_title_text {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.example_card_title_dived {
  width: 36px;
  height: 2px;
  background: #FFB119;
  border-radius: 1px;
  margin-left: auto;
  margin-right: auto;
}

.cp_background {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/main/backgroundImage/background_cp.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: toRem(860);
  background-color: #ffffff;
}

.cp_steps {
  display: flex;
  width: 100vw;
  align-items: center;
  margin-top: toRem(145);
  justify-content: center;
}

.cp_step_item {
  display: flex;
  flex-direction: column;
}

.cp_step_item_img {
  width: toRem(50);
  height: toRem(50);
  margin-left: toRem(-8);
  min-width: 20px;
  min-height: 20px;
  margin-bottom: toRem(24);
}

.cp_item_title {
  font-size: toRem(50);
  margin-left: toRem(-9);
  font-weight: 400;
  color: #FFB119;
}

.cp_item_text {
  font-size: toRem(20);
  color: #666666;
  display: flex;
  margin-left: toRem(-25);
  margin-top: toRem(24);
}

.cp_item_img {
  width: toRem(29);
  height: toRem(29)
}

.cp_item_dived {
  width: toRem(244);
  height: 1px;
  border: 1px solid #999999;
}

.collapse_img {
  width: toRem(43);
  height: toRem(77);
}

.about_more_btn {
  width: toRem(190);
  height: toRem(40);
  color: #FFB119;
  border-color: #FFB119;
  font-size: toRem(18);
  min-width: 140px;
  min-height: 40px;
}

@Media screen and (max-width: 1200px) {
  .mode_header {
    height: auto;
  }
}


@Media screen and (max-width: 1024px) {
  .mode_cases {
    height: auto;
  }
  .solution_mode {
    height: auto;
  }
  .solution_mode_cards {
    height: auto;
  }
  .mode_text {
    font-size: 16px;
  }
  .solution_title_color_orange {
    font-size: 20px;
  }
  .mode_title {
    font-size: 18px;
  }
  .solution_card {
    font-size: 14px;
  }
  .monthly_mess_title {
    font-size: 14px;
  }
  .monthly_mess_text {
    font-size: 12px;
  }
  .about_more_btn{
    font-size: 10px;
  }
  .adv_text{
    font-size: 10px;
  }
  .adv_foot_text{
    font-size: 12px;
  }
}

@Media only screen and (max-width: 900px) {
  .cp_item_img {
    width: toRem(50);
    height: toRem(50);
  }
}

@Media screen and (max-width: 576px) {
  .adv_background {
    height: auto;
    min-height: 290px;
    //height: 290px;
  }
  .adv_data_list {
    width: 50px;
    height: 50px;
  }
  .adv_sum {
    width: 70px;
  }
  .banner_img {
    height: auto;
  }
  .cp_background {
    height: auto;
  }
  .collapse_img {
    width: auto;
    height: auto;
  }
}

