@import "../../App.scss";

.card_container {
  background-color: #ffffff;
  width: toRem(1300);
  height: toRem(235);
  box-shadow: 0 6px 26px 5px rgba(0, 59, 140, 0.07);
  border-radius: toRem(15);
  padding-right: toRem(30);
  cursor: pointer;
}

.card_container_head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: toRem(35);
  margin-top: toRem(28);
}

.card_container_title {
  margin-left: toRem(13);
  font-size: toRem(20);
  font-family: AlibabaPuHuiTiM;
}

.card_container_text {
  display: flex;
  align-items: center;
}

.card_img {
  width: toRem(417);
  height: toRem(235);
  border-top-left-radius: toRem(15);
  border-bottom-left-radius: toRem(15);
}

.card_dot {
  width: toRem(12);
  height: toRem(12);
  min-width: 12px;
  min-height: 12px;
}

.card_text {
  font-family: AlibabaPuHuiTiR;
  line-height: toRem(30);
  letter-spacing: 1.5px;
  font-size: toRem(16);
  margin-left: toRem(26);
  margin-top: toRem(16);
  text-align: justify;
}

@media screen and (max-width: 1024px) {
  .card_container {
    height: auto;
    padding-right: 0;
  }
  .card_text {
    line-height: normal;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .card_img {
    width: 100%;
    height: auto;
  }
  .card_container_title{
    font-size: 14px;
  }
}