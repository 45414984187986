@import "../../App.scss";

.system_card_bck {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-bottom: toRem(100);
}

.system_card {
  width: toRem(594);
  background: linear-gradient(180deg, #FAFAFA 0%, #FFFFFF 100%);
  box-shadow: 0px 5px 20px 0px rgba(62, 89, 123, 0.1);
  border-radius: 19px;
  border: 1px solid;
  border-image: linear-gradient(135deg, rgba(240, 240, 240, 1), rgba(243, 245, 255, 1), rgba(242, 244, 255, 1)) 1 1;
  min-width: 270px;
  padding: toRem(60) toRem(70) toRem(58) toRem(70);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.system_card_title {
  font-size: toRem(24);
  color: #333333;
  margin-top: toRem(38);
  font-family: AlibabaPuHuiTiM;
}

.system_card_text {
  font-size: toRem(18);
  color: #666666;
  line-height: 30px;
  margin-top: toRem(10);
  font-family: AlibabaPuHuiTiR;
}

.platform_character {
  height: toRem(1617);
  min-height: 1400px;
}

.after_sales_container {
  margin-top: 20px;
  width: 100%;
  height: toRem(500);
}

.after_sales_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.after_sales_card_text {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  color: #333333;
  text-align: center;
}

.service_container {
  width: toRem(1305);
  height: toRem(630);
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(0, 1, 75, 0.11);
  border-radius: 15px;
  margin-top: toRem(60);
  min-width: 270px;
  min-height: 200px;
  padding: toRem(50) toRem(150) toRem(50) toRem(150);
}

.service_container_nav {
  display: flex;
  justify-content: space-around;
}

.service_container_item_dived {
  width: toRem(90);
  min-width: 48px;
  height: toRem(4);
  background: #455278;
  border-radius: 2px;
  position: relative;
  top: 57%;
  left: 5%;
}

.service_container_nav_title {
  font-size: toRem(24);
  font-family: AlibabaPuHuiTiR;
  cursor: pointer;
}

.service_container_nav_dived {
  width: toRem(842);
  height: 1px;
  border: 1px solid rgba(69, 82, 120, 0.39);
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-width: 210px;
}

.service_container_body {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: toRem(60);
}

.service_container_res_image {
  width: toRem(300);
  height: toRem(300);
  min-width: 40px;
  min-height: 40px;
}

.service_container_repair_image {
  width: toRem(145);
  height: toRem(177);
  margin-top: toRem(20);
  min-width: 40px;
  min-height: 40px;
}

.service_container_res_title {
  font-size: toRem(20);
  font-family: AlibabaPuHuiTiM;
  color: #455278;
}

.service_container_res_text {
  width: toRem(504);
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  color: #455278;
  margin-top: toRem(20);
  line-height: 28px;
  letter-spacing: 3.5px;
}

.service_container_repair_card {
  width: toRem(342);
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  padding: toRem(44) toRem(32) toRem(12) toRem(32);
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 270px;
}

.service_container_repair_card_title {
  font-size: toRem(20);
  font-family: AlibabaPuHuiTiM;
  color: #455278;
  line-height: 27px;
  text-align: center;
}

.service_container_repair_card_text {
  font-size: toRem(14);
  font-family: AlibabaPuHuiTiR;
  color: #455278;
  line-height: 28px;
}

.characteristic_img {
  width: toRem(500);
  height: toRem(300);
  min-width: 270px;
  min-height: 170px;
}

.page_show_container {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
}


@media screen and (max-width: 1200px) {
  .after_sales_container {
    height: auto;
  }
  .service_container {
    height: auto;
  }
  .service_container_item_dived {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .system_card_title {
    font-size: 16px;
  }
  .system_card_text {
    font-size: 13px;
  }
  .service_container_nav_title {
    font-size: 12px;
  }
  .service_container_res_text{
    font-size: 11px;
  }
}