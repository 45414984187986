@import "../../App.scss";

.title_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: toRem(40);
  font-family: AlibabaPuHuiTiM;
  color: #333333;
}

.after_sales_container {
  margin-top: 20px;
  width: 100%;
  height: toRem(500);
}

.after_sales_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.after_sales_card_text {
  font-size: toRem(20);
  font-family: AlibabaPuHuiTiR;
  color: #333333;
  text-align: center;
  margin-top: toRem(22);
}

.service_container {
  width: toRem(1350);
  height: toRem(630);
  min-height: 500px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(0, 1, 75, 0.11);
  border-radius: 15px;
  margin-top: toRem(60);
  min-width: 270px;
  padding: toRem(50) toRem(150) toRem(50) toRem(150);
}

.service_container_nav {
  display: flex;
  justify-content: space-around;
}

.service_container_item_dived {
  width: toRem(90);
  min-width: 48px;
  height: toRem(4);
  background: #455278;
  border-radius: 2px;
  position: relative;
  top: 56%;
  left: 5%;
}

.service_container_nav_title {
  font-size: toRem(24);
  font-family: AlibabaPuHuiTiR;
  cursor: pointer;
}

.service_container_nav_dived {
  width: toRem(790);
  height: 1px;
  border: 1px solid rgba(69, 82, 120, 0.39);
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-width: 210px;
}

.service_container_body {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: toRem(60);
}

.service_container_res_image {
  width: toRem(193);
  height: toRem(204);
  min-width: 40px;
  min-height: 40px;
}

.service_container_repair_image {
  width: toRem(145);
  height: toRem(177);
  margin-top: toRem(20);
  min-width: 40px;
  min-height: 40px;
}

.service_container_res_title {
  font-size: toRem(20);
  color: #455278;
  font-family: AlibabaPuHuiTiM;
}

.service_container_res_text {
  font-size: toRem(20);
  color: #455278;
  margin-top: toRem(20);
  font-family: AlibabaPuHuiTiR;
}

.service_container_repair_card {
  width: toRem(342);
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  padding: toRem(44) toRem(32) toRem(12) toRem(32);
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 270px;
}

.service_container_repair_card_title {
  font-size: toRem(20);
  font-family: AlibabaPuHuiTiM;
  color: #455278;
  line-height: 27px;
  text-align: center;
}

.service_container_repair_card_text {
  font-size: toRem(14);
  font-family: AlibabaPuHuiTiR;
  color: #455278;
  line-height: 28px;
}

.concat {
  width: toRem(640);
  height: toRem(174);
  background: #FFFFFF;
  border-radius: 10px;
  padding: toRem(35) toRem(70) toRem(35) toRem(60);
  display: flex;
  min-width: 270px;
  align-items: center;
  min-height: 80px;
}

.concat_title {
  font-size: toRem(26);
  font-family: AlibabaPuHuiTiM;
  color: #5B370B;
  letter-spacing: 1px;
}

.concat_pad {
  padding: toRem(120) toRem(280) toRem(120) toRem(280)
}

.concat_dived {
  width: toRem(390);
  height: 1px;
  border: 1px solid #DBDFE7;
  margin-top: toRem(20);
}

.concat_text {
  font-size: toRem(24);
  font-family: AlibabaPuHuiTiR;
  color: #5B370B;
  letter-spacing: 1px;
  margin-top: toRem(20);
}

.concat_big {
  width: toRem(1320);
  background: #FFFFFF;
  border-radius: 10px;
  min-width: 270px;
  display: flex;
  padding: toRem(35) toRem(80) toRem(35) toRem(60);
  align-items: center;
  min-height: 100px;
}

.concat_dived_big {
  width: toRem(1010);
  height: 1px;
  border: 1px solid #DBDFE7;
  margin-top: toRem(20);
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 22px;
  }
  .after_sales_card_text {
    font-size: 14px;
  }
  .service_container_nav_title{
    font-size: 12px;
  }
  .service_container_res_title{
    font-size: 16px;
  }
  .service_container_res_text{
    font-size: 14px;
  }
  .service_container_repair_card_title{
    font-size: 16px;
  }
  .service_container_repair_card_text{
    font-size: 14px;
  }
  .concat_title{
    font-size: 16px;
  }
  .concat_text{
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .after_sales_container {
    height: auto;
  }
  .service_container {
    height: auto;
  }
  .service_container_item_dived {
    display: none;
  }
}