@import "../../App.scss";

.operation_pad {
  width: 100vw;
  padding: toRem(60) 0 toRem(80) toRem(310);
  background-color: #ffffff;
}

.tech_container {
  background-repeat: no-repeat;
  background-size: cover;
  width: toRem(1920);
  padding: toRem(125) 0 toRem(125) toRem(310);
}

.waiMai_tech_container {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/pos/backgroundImage/bck_waiMai_tech.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: toRem(1920);
  padding: toRem(130) toRem(310) toRem(130) toRem(286);
  margin-top: toRem(-5);
}

.tech_bracelet {
  width: toRem(576);
  min-width: 250px;
  background: #5D6B73;
  border-radius: toRem(19);
  opacity: 0.75;
  padding: toRem(53) toRem(60) toRem(53) toRem(61);
  display: flex;
  align-items: center;
  margin-top: toRem(36);
}

.tech_bracelet_title {
  font-size: toRem(40);
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 1px;
}

.tech_bracelet_card_title {
  font-size: toRem(18);
  font-weight: 600;
  color: #ffffff;
}

.tech_bracelet_card_text {
  font-size: toRem(18);
  color: #DEDEDE;
  margin-top: toRem(8);
}

.tech_bracelet_card_margin {
  margin-left: toRem(20);
}

.accessory_card_container_btn {
  position: relative;
  width: toRem(362);
  height: toRem(53);
  border-radius: toRem(27);
  border: 1px solid #EDEDED;
  top: toRem(30);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  min-height: 30px;
}

.accessory_card_container_btn_not_active {
  font-size: toRem(18);
  width: 50%;
  height: toRem(53);
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 30px;
  text-align: center;
  font-family: AlibabaPuHuiTiR;
}

.accessory_card_container_btn_active {
  width: 50%;
  height: toRem(53);
  background: #FFFFFF;
  border-radius: toRem(27);
  font-size: toRem(18);
  color: #333333;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  font-family: AlibabaPuHuiTiM;
}

.operation_video {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //margin-top: toRem(59);
  width: toRem(1920);
  height: toRem(489);
  min-width: 270px;
  min-height: 220px;
}

@media screen and (max-width: 1024px) {
  .tech_bracelet_card_title{
    font-size: 11px;
  }
  .tech_bracelet_card_text{
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .accessory_card_container_btn {
    width: 100%;
  }
}
