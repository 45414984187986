@import "../../App.scss";

.banner_smart {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/kitchenSignage/banner/banner_smart_signage.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: toRem(1920);
  padding: toRem(223) toRem(210) toRem(213) toRem(1216);
  height: auto;
}

.banner_smart_title {
  font-size: toRem(50);
  font-family: AlibabaPuHuiTiB;
  color: #333333;
  line-height: 69px;
}

.banner_smart_text {
  width: toRem(445);
  font-size: toRem(16);
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #999999;
  line-height: 28px;
  margin-top: toRem(36);
  min-width: 250px;
}

.banner_smart_card_container_bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner_smart_card_container_bottom_text {
  width: toRem(200);
  font-size: toRem(16);
  color: #666666;
  margin-top: toRem(22);
  text-align: center;
  min-width: 100px;
  font-family: AlibabaPuHuiTiR;
}

.tech_container {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/kitchenSignage/backgroundImage/bck_card_bracelet_tech.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: toRem(130) toRem(310) toRem(130) toRem(310);
  width: toRem(1920);
}

@media screen and (max-width: 1024px) {
  .banner_smart {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
    background-size: 100% 100%;
  }
  .banner_smart_title {
    margin-left: auto;
    margin-right: auto;
    //text-align: center;
    line-height: normal;
    font-size: 16px;
  }
  .banner_smart_text {
    line-height: normal;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .banner_smart_card_container_bottom{
    justify-content: space-between;
    margin-top: 50px;
  }
  .banner_smart_card_container_bottom_text {
    font-size: 11px;
  }

}