@import "../../App.scss";

.market_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  padding: toRem(100) toRem(310) toRem(100) toRem(310);
  height: toRem(1100);
}

.market_title {
  font-size: toRem(40);
  font-family: AlibabaPuHuiTiB;
  color: #333333;
  line-height: 56px;
  text-align: center;
}

.market_card_num {
  font-size: toRem(50);
  text-align: center;
  font-family: AlibabaPuHuiTiM;
  color: #FFB119;
  line-height: 69px;
}

.market_card_unit {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiM;
  color: #FFB119;
  line-height: 25px;
}

.market_card_text {
  font-size: toRem(26);
  font-family: AlibabaPuHuiTiM;
  color: #666666;
  line-height: 35px;
  text-align: center;
}

.core_adv {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: toRem(120) toRem(250) toRem(120) toRem(250);
}

.core_adv_title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.core_adv_anim {
  height: toRem(545);
}

.core_card_col {
  display: flex;
  justify-content: center;
  margin-top: toRem(40);
}

.core_adv_card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.core_adv_card_text {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  color: #333333;
  line-height: 25px;
  text-align: center;
  width: 200px;
  margin-top: toRem(20);
}

.mode {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: toRem(100) toRem(210) toRem(100) toRem(210);
  overflow: hidden;
}

.mode_img {
  width: 100%;
  height: toRem(1080);
}


.cp_text {
  font-size: toRem(18);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 25px;
  margin-top: toRem(20);
  text-align: center;
}

.cp_background_process {
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/backgroundImage/bck_cooperation_process.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: toRem(735);
  min-height: 200px;
  background-color: #ffffff;
}

.cp_steps {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  margin-top: toRem(140);
  margin-left: toRem(80);
}

.cp_step_item {
  display: flex;
  flex-direction: column;
}

.cp_step_item_img {
  width: toRem(50);
  height: toRem(50);
  margin-bottom: toRem(24);
  min-width: 20px;
  min-height: 20px;
  margin-left: toRem(-8);
}

.cp_item_dived {
  width: toRem(234);
  height: 1px;
  border: 1px dashed #B5B5B5;
}

.cp_item_title {
  font-size: toRem(50);
  font-weight: 400;
  color: #FFF6E6;
  line-height: 59px;
  margin-left: toRem(-10);
}

.cp_item_text {
  font-size: toRem(20);
  color: #FFFFFF;
  line-height: 27px;
  margin-left: toRem(-23);
  font-family: AlibabaPuHuiTiM;
  margin-top: toRem(24);
}

.cp_item_img {
  width: toRem(29);
  height: toRem(29);
}

.qualification_bck {
  width: toRem(1920);
  background-image: url(https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/backgroundImage/bck_qualifications.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.qualification_img {
  width: toRem(445);
  height: auto;
  display: flex;
  justify-content: center;
  min-width: 270px;
}

.cp_container {
  display: flex;
  margin-top: toRem(76);
}

.cp_china_image {
  width: toRem(950);
  height: toRem(760);
}

.cp_list_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: toRem(230);
}

.cp_list_data_item_num {
  font-size: toRem(62);
  font-family: AlibabaPuHuiTiB;
  font-weight: 600;
  color: #FF9713;
  letter-spacing: 1px;
}

.cp_list_data_item_unit {
  font-size: toRem(26);
  font-family: AlibabaPuHuiTiM;
  font-weight: 600;
  color: #666666;
  display: flex;
  align-items: center;
  margin-left: toRem(15);
  margin-top: toRem(20);
}

.cp_list_data_item_text {
  font-size: toRem(24);
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #999999;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .market_container {
    height: auto;
  }
  .core_adv_anim {
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .core_adv{
    padding: toRem(120) toRem(20) toRem(120) toRem(20);
  }
  .market_card_num{
    font-size: 32px;
  }
  .market_card_unit{
    font-size: 14px;
  }
  .market_card_text{
    font-size: 24px;
  }
  .market_title{
    font-size: 24px;
  }
  .core_adv_card_text{
    font-size: 13px;
  }
  .cp_text{
    font-size: 14px;
  }
  .cp_item_text{
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .cp_item_img {
    width: toRem(50);
    height: toRem(50);
  }
  .cp_list_data_item_text{
    font-size: 10px;
  }
}