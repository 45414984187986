@import "../../App.scss";

.news_detail_container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.news_article_container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: toRem(100) toRem(350) toRem(100) toRem(350);
}

.news_big_title {
  font-size: toRem(32);
  font-family: AlibabaPuHuiTiM;
  color: #333333;
  letter-spacing: 1px;
  margin-left: toRem(30);
}

.new_vertical_line {
  width: 1px;
  height: toRem(92);
  opacity: 0.62;
  border: 1px solid #D9D9D9;
}

.new_list_item {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  text-indent: toRem(20);
  margin-top: toRem(50);

  p {
    margin-top: toRem(15);
  }

  div {
    margin-top: toRem(15);
  }

  img {
    margin-bottom: toRem(40);
  }
}

.news_day {
  font-size: toRem(54);
  font-family: AlibabaPuHuiTiM;
  color: #333333;
  line-height: 74px;
  letter-spacing: 2px;
}

.new_year_month_text {
  font-size: toRem(18);
  font-family: AlibabaPuHuiTiR;
  color: #333333;
  line-height: 25px;
}

.news_item_text {
  font-size: toRem(19);
  color: black;
  line-height: toRem(30);
  letter-spacing: 1.5px;
}

.news_date {
  display: flex;
  align-items: center;
  margin-right: toRem(30);
  margin-top: toRem(20);
}

.new_line {
  width: toRem(1600);
  height: 1px;
  border: 1px solid #D9D9D9;
  margin-top: toRem(40);
}

;
.news_detail_title {
  font-size: toRem(32);
  font-family: AlibabaPuHuiTiM;
  color: #333333;
  line-height: 44px;
}

.news_detail_img {
  margin-left: auto;
  margin-right: auto;
  width: toRem(800);
  height: toRem(600);
  min-width: 270px;
  min-height: 200px;
}

.news_detail_col_img {
  width: toRem(500);
  height: toRem(400);
  min-width: 270px;
  min-height: 100px;
}

.news_detail_btn_group {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: toRem(30);
}

@media screen and (max-width:576px){
  .news_article_container{
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width:768px){
  .news_detail_title{
    width: 100%;
  }
}

@media screen and (max-width:1024px){
  .news_item_text{
    line-height: normal;
  }
}